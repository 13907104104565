import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import Banner from '../Typography/Banner.vue';
export default {
  props: {
    justify: {
      type: String,
      required: false,
      default: 'start'
    },
    align: {
      type: String,
      required: false,
      default: 'top'
    },
    direction: {
      type: String,
      required: false,
      default: 'vertical'
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(Banner, {
        justify: __props.justify,
        align: __props.align,
        direction: __props.direction,
        style: {
          "height": "100%",
          "box-sizing": "border-box"
        }
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 8, ["justify", "align", "direction"]);
    };
  }
};