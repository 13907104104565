import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-f09223a0"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "fullwidth video-container"
};
var _hoisted_2 = {
  class: "overlayed-container",
  style: {
    "z-index": "-1"
  }
};
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
import { computed, inject, onMounted, onUnmounted, reactive } from 'vue';
import { getSize } from '../composables/windowWatcher';
import { throttle } from '../helpers';
export default {
  props: {
    src: {
      required: false,
      type: String
    },
    href: {
      required: false,
      type: String,
      default: ''
    },
    // Property values of object-fill : https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit; should work on both Video & Iframes
    videoFit: {
      required: false,
      type: String,
      default: "cover",
      validator: function validator(v) {
        return ["cover", "contain", "fill", "none", "scale-down", "inherit", "initial", "revert", "revert-layer", "unset"].includes(v.toLowerCase());
      }
    },
    videoUseOverlay: {
      required: false,
      type: Boolean,
      default: false
    },
    // Property values of mix-blend-mode : https://developer.mozilla.org/en-US/docs/Web/CSS/mix-blend-mode
    blendMode: {
      required: false,
      type: String,
      default: "normal"
    },
    useParallax: {
      type: Boolean,
      default: false
    },
    parallaxRate: {
      type: Number,
      default: 1,
      validator: function validator(v) {
        return 0 < v;
      }
    },
    disableTint: {
      type: Boolean,
      default: false
    },
    disableGradient: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;

    _useCssVars(function (_ctx) {
      return {
        "234e5425": _unref(cssHref)
      };
    });

    var baseURL = inject("baseURL");
    var windowSize = getSize();
    var YTEmbedSlug = computed(function () {
      var pathBreakdown = props.src ? new URL(props.src).pathname.match(/[^/]+/g) : '';
      return pathBreakdown[pathBreakdown.length - 1];
    }); // Core Settings: Autoplay, Mute, Controls, Loop, Playlist
    // Optional: Rel, Modestbranding, disablekb, enablejsapi, widget_referrer
    // Core Settings allow for very basic functionality as a background component; Some of the other options might be useful for preserving feel of website, or aiding analytics.

    var YTSettings = reactive({
      autoplay: 1,
      mute: 1,
      controls: 0,
      playlist: YTEmbedSlug.value,
      // Requires YT Vid ID
      loop: 1,
      modestbranding: 1,
      disablekb: 1,
      widget_referrer: new URL(baseURL).hostname
    }); // Core Settings: Background, Muted
    // Optional: title, byline, portrait, keyboard, dnt
    // Same as above. dnt appears to function similarly to widget_referrer

    var VimeoSettings = {
      // h :  should be set by Vimeo; appears as though the hash cannot be processed or stored ahead of time.
      background: 1,
      // Handles Autoplay, looping, & concealing playback controls
      muted: 1,
      dnt: 1
    };
    /* Construct src url with appropriate settings based on Domain of src prop
     * Does not currently check to see if full url is a proper embed url; just checks the domain name
     */

    var url = computed(function () {
      try {
        if (props.src) {
          var _url = new URL(props.src);

          switch (_url.hostname) {
            case "www.youtube.com":
            case "www.youtube-nocookie.com":
              return constructUrl(_url, YTSettings);

            case "player.vimeo.com":
              return constructUrl(_url, VimeoSettings);

            case "antares.sfo2.digitaloceanspaces.com":
            case "antares.sfo2.cdn.digitaloceanspaces.com":
            case "autotune.sfo2.digitaloceanspaces.com":
            case "antares-wp-media.sfo2.digitaloceanspaces.com":
            case "antares-wp-media.sfo2.cdn.digitaloceanspaces.com":
            case "antares-web-frontend.sfo3.digitaloceanspaces.com":
            case "antares-web-frontend.sfo3.cdn.digitaloceanspaces.com":
              // url does not need to be modified; will be played through HTML5 video component rather than iframe
              return _url;

            default:
              // Not an acceptable source for a video
              throw new Error("Unsupported Host provided: ".concat(_url.hostname));
          }
        } else {
          return "";
        }
      } catch (e) {
        console.log(e);
        return "";
      }
    });
    /**
     * Controls what tag to use to play video
     * If the video is from Digital Ocean, we must use the HTML5 Video tag to use autoplay, mute, & loop;
     * without it, it will play on the iframe unlooped & unmuted.
     */

    var isDO = computed(function () {
      return ["antares.sfo2.digitaloceanspaces.com", "antares.sfo2.cdn.digitaloceanspaces.com", "autotune.sfo2.digitaloceanspaces.com", "antares-wp-media.sfo2.digitaloceanspaces.com", "antares-wp-media.sfo2.cdn.digitaloceanspaces.com", "antares-web-frontend.sfo3.digitaloceanspaces.com", "antares-web-frontend.sfo3.cdn.digitaloceanspaces.com"].includes(url.value.hostname);
    });
    /* Construct the embed Get request by adding appropriate attributes to url
     * Input: 
     *      url: URL object
     *      options: object containing url params; defaults to empty
     * Output: Modified URL object containing new or updated params
     */

    var constructUrl = function constructUrl(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options !== {}) {
        for (var i in options) {
          if (options[i] !== undefined && options[i] !== "") {
            url.searchParams.set(i, options[i]);
          }
        }
      }

      return url;
    };

    var scroll = reactive({
      top: props.useParallax ? window.scrollY : 0,
      left: props.useParallax ? window.scrollX : 0
    });
    var cssHref = computed(function () {
      return props.href ? "url(".concat(props.href, ")") : 'none';
    });
    var layers = computed(function () {
      // No readability/ transition layers need to be applied
      if (!(props.href || url.value)) {
        return "";
      }

      var res = [];

      if (!props.disableTint) {
        res.push("tint");
      }

      if (!props.disableGradient) {
        res.push("gradient");
      }

      return res.join(" ");
    });

    var updateScroll = function updateScroll(e) {
      var top = window.scrollY || 0;
      var left = window.scrollX || 0;
      scroll.top = top * props.parallaxRate;
      scroll.left = left * props.parallaxRate;
    };

    onMounted(function () {
      if (props.useParallax) {
        window.addEventListener("scroll", throttle(updateScroll, 17));
      }
    });
    onUnmounted(function () {
      window.removeEventListener("scroll", throttle(updateScroll, 17));
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "background_image fill-container",
        style: _normalizeStyle({
          'background-position': "calc(50% + ".concat(_unref(scroll).left, "px) calc(50% + ").concat(_unref(scroll).top, "px)")
        })
      }, null, 4), _unref(windowSize).width > 992 && _unref(url) && _unref(url) !== '' ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "overlayed-container",
        style: _normalizeStyle({
          'mix-blend-mode': __props.videoUseOverlay ? __props.blendMode : 'normal'
        })
      }, [!_unref(isDO) ? (_openBlock(), _createElementBlock("iframe", {
        key: 0,
        class: "fill-container video",
        src: _unref(url),
        frameborder: "0",
        allow: "autoplay",
        style: _normalizeStyle({
          'object-fit': __props.videoFit.toLowerCase(),
          'object-position': "calc(50% + ".concat(_unref(scroll).left, "px) calc(50% + ").concat(_unref(scroll).top, "px)")
        })
      }, null, 12, _hoisted_3)) : (_openBlock(), _createElementBlock("video", {
        key: 1,
        autoplay: "",
        playsinline: "",
        muted: "",
        loop: "",
        src: _unref(url),
        class: "fill-container video",
        style: _normalizeStyle({
          'object-fit': __props.videoFit.toLowerCase(),
          'object-position': "calc(50% + ".concat(_unref(scroll).left, "px) calc(50% + ").concat(_unref(scroll).top, "px)")
        })
      }, null, 12, _hoisted_4))], 4)) : _createCommentVNode("", true)]), _createElementVNode("div", {
        class: _normalizeClass(["contents fill-container", _unref(layers)])
      }, [_renderSlot(_ctx.$slots, "default")], 2)]);
    };
  }
};